import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Explore from "../components/explore"

const HowtoPage = () => (
  <div id="indexPage">
    <Header/>
    <div className="content-pad">
      <h2>Standard Packages</h2>
      <p>
	FISH MED NET is a project that supports the diversification of fishers’ main activity. It
aims to enable fishers and fisher cooperatives to explore different paths in order to
increase their pool of activities with the aim of improving their livelihoods whilst
improving the local economy and the surrounding environment. One such activity is
to promote the use of their structures for services to the community, in which
formalised relationships between private and public entities can take place.
      </p>
	<p>
	For this very reason FISH MED NET has put forth 4 different types of formal agreements, known as standard packages, that will enable discussions between fishers and public-private entities to begin a formal route.
	</p>
	<p>
	Links for the four different types of standard packages can be found here below. These documents are to be used freely as templates and can be edited freely. We hope that these documents can provide the needed help in beginning a positive and fruitful collaboration among all users. The use of these documents are solely at the final users’ risk and the project, partners and organisations ENI CBC MED cannot be held liable for any discrepancies and/or actions evolved from any of the formalised or non formalised agreements done and conducted by the final users.
	</p>
<p> <a href="../../standard-packages-en.zip">Download zip file</a> </p>
      <h2>Explore between our last proposals</h2>
    </div>
    <Explore slug="index"/>

    <Footer/>
  </div>
)

export default HowtoPage
